<template>
    <div>
        <floating-menu :event-id="eventId"></floating-menu>
        <b-card v-if="isLoading && !fetchComplete" class="text-center">
            <b-spinner>Tunggu...</b-spinner>
        </b-card>
        <div v-if="!isLoading && fetchComplete && eventData.version == 2">
          <b-card>
            <b-row>
              <b-col cols="6">
                <p>
                  <b>See Result:</b><br>
                  <a :href="'' + this.eventData.url  + ''" target="_blank">{{ this.eventData.url }}</a>
                </p>
              </b-col>
            </b-row>
          </b-card>
          <b-card >
            <event-form-setting-content :event-id="eventId" :isEdit="isEdit" class="mt-2 pt-75"/>
          </b-card>
        </div>
        <b-card v-if="!isLoading && fetchComplete && eventData.version == 1">
            <h4>Sorry, old event doesn't support this feature yet.</h4>
        </b-card>
    </div>
</template>

<script>

import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import EventFormSettingContent from './EventFormSettingContent.vue'
import FloatingMenu from './FloatingMenu.vue'

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    FloatingMenu,
    EventFormSettingContent,
    },
    data() {
      return {
        eventId: router.currentRoute.params.id,
        isEdit: false,
        isLoading: false,
        fetchComplete: false,
        eventData:{
          url: "-",
          version: 1
        }
      }
    },
    methods: {
      fetchEventData() {
            const id = this.eventId
            this.$http.get('/admin/v1/events/' + id)
            .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
                this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: message,
                },
                })
            }
            else {
                const currentData = res.data.data
                this.eventData = {
                    url: currentData.url,
                    version: currentData.registration_system_version
                    }
                }

                this.fetchComplete = true
            })
        }
    },
    created() {
      this.fetchEventData()
    }
}
</script>